.rhap_container {
  box-shadow: none;
  background-color: unset;
  padding: unset;
  .rhap_progress-section {
    .rhap_progress-container {
      .rhap_progress-bar {
        background-color: #e1e5eb;
        border-radius: 0.2rem;
        .rhap_progress-indicator {
          background-color: white;
          width: 1rem;
          height: 1rem;
          top: -0.363rem;
        }
        .rhap_progress-filled {
          background-color: var(--tw-indigo-600);
          border-radius: 0.2rem;
        }
      }
    }
    .rhap_time,
    .rhap_time + div {
      color: #667085;
      font-size: 0.75rem;
    }
    .rhap_time + div {
      padding-left: 0.125rem;
      padding-right: 0.125rem;
    }
  }
  .rhap_main-controls {
    .rhap_main-controls-button {
      color: var(--tw-grey-800);
      &:hover {
        color: var(--tw-indigo-600);
      }
    }
  }
}
