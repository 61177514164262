.ProseMirror {
  &::selection {
    background-color: var(--tw-indigo-100);
  }
  .is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    pointer-events: none;
    height: 0;
  }
}
