@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-regular.woff') format('woff');
  src: url('../fonts/sherika-regular.woff') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-light.woff') format('woff');
  src: url('../fonts/sherika-light.woff') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-thin.woff') format('woff');
  src: url('../fonts/sherika-thin.woff') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-italic.woff') format('woff');
  src: url('../fonts/sherika-italic.woff') format('woff2');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-bold-italic.woff') format('woff');
  src: url('../fonts/sherika-bold-italic.woff') format('woff2');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-light-italic.woff') format('woff');
  src: url('../fonts/sherika-light-italic.woff') format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-thin-italic.woff') format('woff');
  src: url('../fonts/sherika-thin-italic.woff') format('woff2');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-bold.woff') format('woff');
  src: url('../fonts/sherika-bold.woff') format('woff2');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: Sherika;
  src: url('../fonts/sherika-black.woff') format('woff');
  src: url('../fonts/sherika-black.woff') format('woff2');
  font-weight: 900;
  font-style: normal;
}
