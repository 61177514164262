.vertical-bar-graph,
.line-graph {
  .recharts-yAxis .recharts-cartesian-axis-tick:first-child {
    line {
      stroke: none;
    }
    text {
      font-size: 0;
    }
  }
}

.vertical-bar-graph {
  .recharts-yAxis .recharts-cartesian-axis-tick:last-child {
    line {
      stroke: none;
    }
    text {
      font-size: 0;
    }
  }
}
.horizontal-bar-graph {
  .recharts-xAxis .recharts-cartesian-axis-tick:last-child {
    line {
      stroke: none;
    }
    text {
      font-size: 0;
    }
  }
}

.edit-report-container input[type='number']::-webkit-inner-spin-button,
.edit-report-container input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
